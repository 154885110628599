import React, { useState } from "react";

const BusinessFinanceForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    coRegNo: "",
    address: "",
    telNo: "",
    city: "",
    county: "",
    postcode: "",
    faxNo: "",
    contact: "",
    position: "",
    employees: "",
    mobile: "",
    businessType: "",
    periodTrading: "",
    premises: "",
    bankName: "",
    branchAddress: "",
    overdraftLimit: "",
    currentBalance: "",
    securedAgainst: "",
    sortCode: "",
    accountNo: "",
    bankContact: "",
    partnerName: "",
    partnerDOB: "",
    partnerStatus: "",
    partnerAddress: "",
    partnerPostcode: "",
    partnerTimeAtAddress: "",
    partnerOwnership: "",
    partnerValue: "",
    partnerMortgage: "",
    partnerTel: "",
    partnerMobile: "",
    partnerPrevAddress: "",
    equipmentDescription: "",
    newEquipment: "",
    usedEquipment: "",
    cost: "",
    vehicleMake: "",
    vehicleModel: "",
    vehicleTotal: "",
    vehicleRegNo: "",
    vehicleRegDate: "",
    vehicleMileage: "",
    cashDeposit: "",
    supplier: "",
    supplierContact: "",
    balanceToFinance: "",
    partExchange: "",
    supplierTel: "",
    supplierFax: "",
    supplierMobile: "",
    dfr: "",
    declarationName: "",
    declarationSigned: "",
    periodProfile: "",
    facilityType: "",
  });

  const [role, setRole] = useState(null);
  const [loginData, setLoginData] = useState({ username: "", password: "" });

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (loginData.username === "admin" && loginData.password === "admin123") {
      setRole(1); // Super Admin
    } else if (loginData.username === "user" && loginData.password === "user123") {
      setRole(2); // Regular User
    } else {
      alert("Invalid credentials");
    }
  };

  return (
    <div className="container mx-auto p-6">
      {!role ? (
        <div className="login-form">
          <h2 className="text-xl font-bold text-center mb-4">Login</h2>
          <form onSubmit={handleLogin} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Username</label>
              <input
                type="text"
                name="username"
                value={loginData.username}
                onChange={handleLoginChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Password</label>
              <input
                type="password"
                name="password"
                value={loginData.password}
                onChange={handleLoginChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
              />
            </div>
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Login
            </button>
          </form>
        </div>
      ) : (
        <>
          <h2 className="text-xl font-bold text-center mb-4">Business Finance Form</h2>
          <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            {Object.keys(formData).map((key) => (
              <div key={key} className="mb-4">
                
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {key.replace(/([A-Z])/g, " $1").trim()}
                </label>
                <input
                  type="text"
                  name={key}
                  value={formData[key]}
                  onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                />
              </div>
            ))}
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Submit
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default BusinessFinanceForm;
