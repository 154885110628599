import { SimpleButton } from "components/Buttons/SimpleButton";
import Header from "components/Header/header";
import InputWithTitle from "components/Inputs/InputsWithTitle";
import ModalStatus from "components/Modals";
import { Text } from "components/Typography/Typography";
import Dropdownwithtitle from "components/dropdowns/Dropdownwithtitle";
import Table from "components/tables/table";
import { CourseDates, CoursesName, Form_Fields } from "data/formData";
import Send_Email from "networking/networking";
import "pages/Home/index.css";
import "pages/TrainingForm/index.css";
import { useEffect, useRef, useState } from "react";
import { validateEmail } from "utils/method";
import animation from "assets/lottiefiles/Animation - 1714729928900.json";
import errorLottie from "assets/lottiefiles/Animation - 1716882917673 .json";
import { Helmet } from "react-helmet";
const TrainingForm = () => {
  const [infoValues, setInfoValues] = useState({});
  const [trainingForm, setTrainingForm] = useState({
    isDropdownShow: false,
    selectedValue: null,
    isTitleDropdown: false,
    days: null,
    price: "",
    disability: null,
    selectedIndex: null,
    explaination: "",
    registration_number: "",
    isError: "",
    isErrorShow: false,
    filterCourses: [],
    isSpinner: false,
    isOpenModal: false,
    isCheckEmptyField: false,
    alertMessage: null,
    status: null,
  });
  const [lottieFile, setLottieFile] = useState(null);

  const scrollRef = useRef();

  useEffect(() => {
    setInfoValues((prevValues) => {
      const newValues = { ...prevValues };
      trainingForm?.selectedValue?.includes("Periodic")
        ? (newValues.driving_license_number = "")
        : delete newValues.driving_license_number;
      return newValues;
    });
  }, [trainingForm?.selectedValue]);

  useEffect(() => {
    setTrainingForm({ ...trainingForm, filterCourses: CoursesName });
  }, []);
  const onDropdownOpen = (item) => {
    setTrainingForm({
      ...trainingForm,
      isDropdownShow: !trainingForm?.isDropdownShow,
      selectedValue: item,
      price: item?.includes("Operator")
        ? "£250.00"
        : item?.includes("IRETEC")
        ? "£1300"
        : item?.includes("CILT")
        ? "£1495"
        : item?.includes("Periodic")
        ? " £60 per Day"
        : item?.includes("Delivering Training (RQF)")
        ? " £350"
        : item?.includes("Workplace")
        ? " £550 + Exam Fee  (£50)  3 Days"
        : item?.includes("CPC") ? " £350 " : item?.includes("IRTEC") ? "£1495 " : '',
    });
  };

  const onCheck = (index) => {
    setTrainingForm({ ...trainingForm, selectedIndex: index });
  };

  const onCheckDisability = (currentIndex) => {
    setTrainingForm({ ...trainingForm, disability: currentIndex });
  };

  const onChangeValues = (val, key) => {
    setInfoValues((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const onSearchCourse = (searchedCourse) => {
    setTrainingForm({
      ...trainingForm,
      filterCourses: CoursesName?.filter((item) =>
        item?.toLowerCase().includes(searchedCourse.toLowerCase())
      ),
    });
  };

  const onSubmit = async () => {
    const form_fields = Object.keys(Form_Fields);
    const info_values = Object.keys(infoValues);

    const isErrorShow = form_fields.every((currentValue) => {
      return currentValue !== "middle_name"
        ? info_values?.includes(currentValue) && infoValues[currentValue] !== ""
        : info_values?.includes("driving_license_number")
        ? infoValues["driving_license_number"] !== ""
        : true;
    });

    const check_days =
      trainingForm?.selectedValue?.includes("Periodic") &&
      trainingForm?.days == null;

    if (
      !isErrorShow ||
      validateEmail(infoValues["email"]) ||
      trainingForm?.selectedValue == null ||
      check_days
    ) {
      setTrainingForm({
        ...trainingForm,
        isErrorShow: true,
        isCheckEmptyField: true,
      });
      scrollRef?.current?.scrollIntoView(true);
    } else {
      const payload = { ...infoValues, ...trainingForm, status: 5,form_name:'MS Blue - Training Course Registration Form' };
      try {
        setTrainingForm({
          ...trainingForm,
          isSpinner: true,
          isErrorShow: false,
          isCheckEmptyField: false,
        });

        await Send_Email(payload).then((resp) => {
          if (resp?.status == 200) {
            setInfoValues((prevValues) => {
              const clearedState = Object.keys(prevValues).reduce(
                (acc, key) => {
                  acc[key] = "";
                  return acc;
                },
                {}
              );
              return clearedState;
            });
          }
        });
        setTrainingForm((trainingForm) => ({
          ...trainingForm,
          isSpinner: false,
          isOpenModal: true,
          isErrorShow: false,
          days: null,
          selectedValue: null,
          selectedIndex: "",
          disability: "",
          explaination: "",
          registration_number: "",
          price: null,

          alertMessage: "Email Successfully Sent",
        }));
        setLottieFile(animation);
      } catch (error) {
        setTrainingForm((trainingForm) => ({
          ...trainingForm,
          isSpinner: false,
          isOpenModal: true,
          alertMessage: "Email Failed",
          status: null,
        }));
        setLottieFile(errorLottie);
      }
    }
  };

  return (
    <>
    <Helmet>
        <title>Registration Form</title>
      </Helmet>
      <Header />
      <div className="container">
        <h2 className="text-center mb-5">Training Course Registration Form</h2>
        <div className="d-flex flex-wrap" ref={scrollRef}>
          {Object.keys(Form_Fields).map((item, index) => (
            <>
              <InputWithTitle
                key={index}
                value={infoValues[item]}
                containerWidth={index < 3 ? "100%" : "100%"}
                width={"100%"}
                className={"training-main-input"}
                inputClass={"training-input"}
                label={`${item.replace("_", " ").toLocaleUpperCase()}*`}
                labelClass={"training-input-label"}
                placeHolder={`Enter ${item.replace("_", " ")}`}
                errorMesg={
                  item == "email" &&
                  infoValues[item] !== "" &&
                  validateEmail(infoValues[item])
                    ? "Please Enter Verified Email"
                    : item !== "middle_name" && "Please Enter This Field"
                }
                onChange={(val) => onChangeValues(val, item, index)}
                errorColor={"red-color"}
                isErrorShow={
                  (trainingForm?.isCheckEmptyField && infoValues[item] == "") ||
                  (!Object.hasOwn(infoValues, item) &&
                    trainingForm?.isErrorShow) ||
                  (item == "email" &&
                    infoValues[item] !== "" &&
                    validateEmail(infoValues[item]))
                }
              />
            </>
          ))}
          <Dropdownwithtitle
            className={"training-input"}
            isShowDropdown={trainingForm?.isDropdownShow}
            label={"COURSE NAME"}
            value={trainingForm?.selectedValue}
            Data={trainingForm?.filterCourses}
            isSearchBarShow={true}
            onDropdownOpen={(item) => onDropdownOpen(item)}
            onSearchCourse={(val) => {
              onSearchCourse(val);
            }}
            errorMesg={"Please Enter This Field"}
            errorColor={"red-color"}
            isErrorShow={
              trainingForm?.isErrorShow && trainingForm?.selectedValue == null
            }
          />

          <div style={{ width: "100%" }}>
            {trainingForm?.price && (
              <h4 className="mt-3">Starting from {trainingForm?.price}</h4>
            )}
            {trainingForm?.selectedValue?.includes("Periodic") && (
              <div>
                <Dropdownwithtitle
                  label={"Number of Days Required"}
                  Data={[1, 2, 3, 4, 5]}
                  isSearchBarShow={false}
                  value={trainingForm?.days}
                  errorMesg={"Please Enter This Field"}
                  errorColor={"red-color"}
                  isErrorShow={
                    trainingForm?.isErrorShow && trainingForm?.days == null
                  }
                  onDropdownOpen={(item) => {
                    setTrainingForm({
                      ...trainingForm,
                      isTitleDropdown: !trainingForm?.isTitleDropdown,
                      days: item,
                      isErrorShow: false,
                    });
                  }}
                  isShowDropdown={trainingForm?.isTitleDropdown}
                />
                <InputWithTitle
                  containerWidth={"100%"}
                  width={"100%"}
                  label={"Driving License Number"}
                  className={"training-main-input"}
                  inputClass={"training-input"}
                  labelClass={"training-input-label"}
                  placeHolder={"Enter Driving License Number"}
                  errorMesg={"Please Enter This Field"}
                  errorColor={"red-color"}
                  isErrorShow={
                    trainingForm?.isErrorShow &&
                    infoValues["driving_license_number"] == ""
                  }
                  onChange={(val) =>
                    setInfoValues({
                      ...infoValues,
                      driving_license_number: val,
                    })
                  }
                />
              </div>
            )}

            <div>
              {trainingForm?.selectedValue?.includes("CILT") && (
                <>
                  <p className="mx-2 my-2">Exam Date</p>
                  {CourseDates?.map((item, index) => (
                    <div className="d-flex align-items-center" key={index}>
                      <input
                        type="radio"
                        checked={trainingForm?.selectedIndex === index}
                        onChange={() => onCheck(index)}
                      />
                      <p className="mb-0 mx-2 my-2">{item}</p>
                    </div>
                  ))}

                  {trainingForm?.isErrorShow &&
                    trainingForm?.selectedIndex == null && (
                      <Text
                        label={"Please Select date field"}
                        color={"red"}
                        fontSize={11}
                      />
                    )}
                </>
              )}
            </div>

            <p className="my-3 mx-2">
              Does the candidate have any special requirement or disabilities?
            </p>

            <div className="d-flex align-items-center mx-2">
              <input
                type="radio"
                checked={trainingForm?.disability === 1}
                onClick={() => onCheckDisability(1)}
              />
              <p className="mb-0 mx-2 my-2">Yes</p>
            </div>

            <div className="d-flex align-items-center mx-2">
              <input
                type="radio"
                checked={trainingForm?.disability === 0}
                onClick={() => onCheckDisability(0)}
              />
              <p className="mb-0 mx-2 my-2">No</p>
            </div>

            {trainingForm?.disability ? (
              <InputWithTitle
                containerWidth={"100%"}
                width={"100%"}
                label={"If yes, please explain"}
                className={"training-main-input"}
                inputClass={"training-input"}
                labelClass={"training-input-label"}
                placeHolder={"PLease Explain"}
                onChange={(val) =>
                  setTrainingForm({
                    ...trainingForm,
                    explaination: val,
                  })
                }
              />
            ) : null}
          </div>

          {/* <SimpleButton
            onSubmit={() => onSubmit()}
            buttonClass={"my-5 submit-button"}
            label={"Submit"}
            isSpinner={trainingForm?.isSpinner}
          /> */}
          {/* <button onClick={()=>onSubmit()}></button> */}
          <div  style={{ paddingTop: "20px", paddingBottom:"20px" }}>
          <button onClick={()=>onSubmit()} type="button" className="btn btn-primary">Submit</button>
          </div>
        </div>
        <Table />
        <ModalStatus
          isOpenModal={trainingForm?.isOpenModal}
          message={trainingForm?.alertMessage}
          LottieFile={lottieFile}
          setIsOpenModal={() =>
            setTrainingForm({ ...trainingForm, isOpenModal: false })
          }
        />
      </div>
    </>
  );
};

export default TrainingForm;