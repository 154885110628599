import React, { useEffect, useLayoutEffect, useState } from "react";
import Group115 from "assets/images/Group-115.png";
import Group116 from "assets/images/Group-116.png";
import Group118 from "assets/images/Group-118.png";
import Group119 from "assets/images/Group-119.png";
import Group132 from "assets/images/Group-132.png";
import Group133 from "assets/images/Group-133.png";
import Group134 from "assets/images/Group-134.png";
import Group135 from "assets/images/Group-135.png";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { TrainingData } from "data/trainingData";
import { useMobile } from "utils/CustomHooks/useDeviceResponsive";
import Header from "components/Header/header";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const Training = () => {
  const [path, setPath] = useState(null);
  const isMobile = useMobile();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const firstFiveCourses = TrainingData.slice(0, 5);
  const lastTwoCourses = TrainingData.slice(5, 8);

  return (
    <div>
      <Helmet>
        <title>Training</title>
        <link
          rel="canonical"
          href={`https://msblue.uk/${location?.pathname}`}
        />
        <meta
          property="og:url"
          content={`https://msblue.uk/${location?.pathname}`}
        />
      </Helmet>

      {/* <Helmet>
  <title>Training</title>
  <link
    rel="canonical"
    href={`https://msblue.uk/training/${params?.item}`}
  />
  <meta
    property="og:url"
    content={`https://msblue.uk/training/${params?.item}`}
  />
  <meta property="og:title" content="Training" />
  <meta property="og:description" content="Flexible training options which suit operational needs and individual learning
styles to run a compliant and efficient transport operation." />
  <meta
    property="og:image"
    content={`https://msblue.uk/msblue-featured-images/Group-115.png`}
  />
  <meta property="og:type" content="website" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Training" />
  <meta name="twitter:description" content="Flexible training options which suit operational needs and individual learning
styles to run a compliant and efficient transport operation." />
  <meta
    name="twitter:image"
    content={`https://msblue.uk/msblue-featured-images/Group-115.png`}
  />
</Helmet> */}

      <Header />

      {Object.entries(params).length == 0 ? (
        <>
          <section className="Trainings-first-Stsection">
            <div className="container">
              <div className="row" style={{ padding: "0px 0px 60px 0px" }}>
                <div className="col-bg-12 text-lg-start Trainings-We-Offer">
                  <h1 className="fade-right1 text-center">
                    <span style={{ color: "#3A47BC" }}>Transport, </span>
                    <span style={{ color: "#67D3D1" }}>We Offers Are</span>
                  </h1>
                  <p>
                    Flexible training options which suit operational needs and
                    individual learning
                    <br />
                    styles to run a compliant and efficient transport operation.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="ase">
                  <div className="col-md-5" style={{ textAlign: "center" }}>
                    <img src={Group115} />
                    <h4>
                      CILT accredited <br />
                      Transport Manager CPC
                    </h4>
                    <p>
                      The Certificate of Professional Competence (CPC) for
                      Transport Managers in Road Haulage is offered by CILT(UK)
                      on behalf of the Department for Transport and includes a
                      range of subjects related to the business of road
                      transport.
                    </p>
                  </div>
                  <div className="col-sm-2"></div>
                  <div className="col-md-5" style={{ textAlign: "center" }}>
                    <img src={Group116} />
                    <h4>
                      IRTEC Accredited HGV <br />
                      Inspection Technician Training
                    </h4>
                    <p>
                      The course is designed to train and prepare HGV inspection
                      technician to a standard where they can undertake the
                      IRTEC HGV inspection technician assessment and gain the
                      accredited 5-year IRTEC licence for the inspection of HGV
                      vehicles. Along with knowledge of the good vehicle
                      operators commitments to road safety.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="ase">
                  <div className="col-md-5" style={{ textAlign: "center" }}>
                    <img src={Group118} />
                    <h4>
                      Transport Manager <br />
                      CPC Refresher Training
                    </h4>
                    <p>
                      With the ever-changing legislation within the transport
                      industry, the Traffic Commissioners strongly recommend
                      that all Transport Managers undertake refresher training
                      from time to time.
                    </p>
                  </div>
                  <div className="col-sm-2"></div>
                  <div className="col-md-5" style={{ textAlign: "center" }}>
                    <img src={Group119} />
                    <h4>
                      Periodic Driver CPC <br />
                      Training
                    </h4>
                    <p>
                      Required by drivers who passed their practical driving
                      test before September 2009 (HGV) or 2008 (PCV), or who
                      have previously held a CPC entitlement for the relevant
                      driving licence category. You can now book our Driver CPC
                      training courses online.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="Trainings-first-Stsection ">
            <div className="container">
              <div
                className="row text-center"
                style={{ padding: "0px 0px 60px 0px" }}
              >
                <div
                  className="col-bg-12 text-lg-start Trainings-We-Offer"
                  style={{ paddingTop: "39px" }}
                >
                  <h1 className="fade-right1 text-center">
                    <span style={{ color: "#3A47BC" }}>
                      Help us improve your
                    </span>
                    <br />
                    <span style={{ color: "#67D3D1" }}>
                      future learning experience
                    </span>
                  </h1>
                  <div className="text-center" style={{ textAlign: "center" }}>
                    <button className="Trainings-first-button">
                      <a
                        href="https://cscs.msblue.uk/feedback-form/"
                        role="button"
                        style={{ color: "#fff", textDecoration: "auto" }}
                      >
                        {" "}
                        Give Us A Quick Feedback
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="Trainings-first-Stsection ">
            <div className="container">
              <div className="row" style={{ padding: "0px 0px 30px 0px" }}>
                <div
                  className="col-bg-12 text-lg-start Trainings-We-Offer"
                  style={{ paddingTop: "0px" }}
                >
                  <h1 className="fade-right1 text-center">
                    <span style={{ color: "#3A47BC" }}>Transport </span>
                    <span style={{ color: "#67D3D1" }}>Training</span>
                  </h1>
                  <div className="Trainings-first-Getthebest">
                    <p>
                      Get the best out of your workforce with custom and <br />
                      multilingual MS Blue training courses delivered both face
                      to face and online.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="Trainings-pricing-Stsection">
            <div className="container">
              <div className="row d-flex justify-content-center flex-wrap">
                {firstFiveCourses.map((item, index) => (
                  <div
                    key={index}
                    className="col-sm-6 "
                    style={{
                      position: "relative",
                      margin: "10px 0px 10px 0px",
                    }}
                  >
                    <div
                      className="pricing-col-1 "
                      style={{ position: "relative", cursor: "pointer" }}
                      onClick={() =>
                        navigate(item?.route?.replace("...", "training"), {
                          state: item,
                        })
                      }
                    >
                      <div className={!isMobile ? "days" : "days mx-2"}>
                        <p>
                          {item?.days == 1
                            ? item?.days + " Day"
                            : item?.days + " Days"}
                        </p>
                      </div>
                      <div className="row">
                        <div
                          className="col-sm-2 text-center"
                          style={{ padding: "0px" }}
                        >
                          <img
                            src={item.icon}
                            style={{ height: isMobile ? "50px" : "40px" }}
                          />
                        </div>
                        <div
                          className="col-sm-8"
                          style={{ padding: "0px 20px 0px 20px" }}
                        >
                          <h5>{item?.title}</h5>
                        </div>
                      </div>
                      <div className="price">
                        {/* <p>{item?.price}</p> */}
                        <p
                          style={{ padding: "0px" }}
                          dangerouslySetInnerHTML={{ __html: item?.price }}
                        />
                      </div>
                      <div className="text-inner1">
                        <p style={{ height: "111px" }}>{item?.heading}</p>
                      </div>
                    </div>
                    <div
                      className="row row-1"
                      style={{
                        position: "absolute",
                        width: "100%",
                        zIndex: "1",
                        height:"54px",
                      }}
                    >
                      <div className="col-sm-4 BookNow2" style={{ paddingRight: "0px" }}>
                        <a href="/training-form" className="btn" role="button">
                          Book Now&nbsp;<span>➜</span>
                        </a>
                      </div>
                      {item?.pdf_url && (
                        <div
                          className="col-sm-4 BookNow1-1"
                          style={{
                            textAlign: "end",
                            width: "38%",
                            padding: "0px",
                          }}
                        >
                          <a href={item?.pdf_url} className="btn" role="button">
                            2025 Assessment Timetable
                          </a>
                        </div>
                      )}
                      {item?.schedule_url && (
                        <div
                          className="col-sm-4 BookNow"
                          style={{
                            textAlign: "end",
                            width: "30%",
                            paddingLeft: "0px",
                          }}
                        >
                          <a
                            href={item?.schedule_url}
                            className="btn"
                            role="button"
                          >
                            Schedule &nbsp;<span>➜</span>
                          </a>
                        </div>
                      )}
                    </div>
                    <div
                      className="row row-2"
                      style={{
                        position: "absolute",
                        width: "100%",
                        zIndex: "1",
                      }}
                    >
                      {item?.pdf_url && (
                        <div
                          className="col-sm-4 BookNow1-2"
                          style={{ width: "38%", padding: "0px" }}
                        >
                          <a href={item?.pdf_url} className="btn" role="button">
                            2025 Assessment Timetable
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section className="Trainings-first-Stsection ">
            <div className="container">
              <div className="row" style={{ padding: "40px 0px 10px 0px" }}>
                <div
                  className="col-bg-12 text-lg-start Trainings-We-Offer"
                  style={{ paddingTop: "0px" }}
                >
                  <h1 className="fade-right1 text-center">
                    <span style={{ color: "#3A47BC" }}>Health & Safety </span>
                    <span style={{ color: "#67D3D1" }}>Training</span>
                  </h1>
                  <div className="Trainings-first-Getthebest">
                    <p>
                      Whatever your health and safety requirements, you have
                      come to the right place.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="Trainings-pricing-Stsection">
            <div className="container">
              <div className="row d-flex justify-content-center flex-wrap">
                {lastTwoCourses.map((item, index) => (
                  <div
                    key={index + 5}
                    className="col-sm-6 "
                    style={{
                      position: "relative",
                      margin: "10px 0px 10px 0px",
                    }}
                  >
                    <div
                      className="pricing-col "
                      style={{ position: "relative", cursor: "pointer" }}
                      onClick={() =>
                        navigate(item?.route?.replace("...", "training"), {
                          state: item,
                        })
                      }
                    >
                      <div className={!isMobile ? "days" : "days mx-2"}>
                        <p>
                          {item?.days == 1
                            ? item?.days + " Day"
                            : item?.days + " Days"}
                        </p>
                      </div>
                      <div className="row">
                        <div
                          className="col-sm-2 text-center"
                          style={{ padding: "0px" }}
                        >
                          <img
                            src={item.icon}
                            style={{ height: isMobile ? "50px" : "40px" }}
                          />
                        </div>
                        <div
                          className="col-sm-8"
                          style={{ padding: "0px 20px 0px 20px" }}
                        >
                          <h5>{item?.title}</h5>
                        </div>
                      </div>
                      <div className="price">
                        <p
                          style={{ padding: "0px" }}
                          dangerouslySetInnerHTML={{ __html: item?.price }}
                        />
                      </div>
                      <div className="text-inner2">
                        <p style={{ height: "176px" }}>{item?.heading}</p>
                      </div>
                    </div>
                    <div
                      className="row row-3"
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        width: "100%",
                        zIndex: "1",
                        height: "54px",
                      }}
                    >
                      <div
                        className="col-sm-6 BookNow2"
                        style={{ paddingRight: "0px" }}
                      >
                        <a href="/training-form" className="btn" role="button">
                          Book Now&nbsp;<span>➜</span>
                        </a>
                      </div>
                      {item?.schedule_url && (
                        <div
                          className="col-sm-6 BookNow"
                          style={{ textAlign: "end", width: "50%" }}
                        >
                          <a
                            href={item?.schedule_url}
                            className="btn"
                            role="button"
                          >
                            Schedule &nbsp;<span>➜</span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>


          <section className="Trainings-pricing-Stsection">
            <div className="container">
              <div className="row d-flex justify-content-center flex-wrap">
             
                  <div className="col-sm-6 " style={{  position: "relative",  margin: "10px 0px 10px 0px", }} >
                    <div  className="pricing-col " style={{ position: "relative", cursor: "pointer" }} >
                      <div className={!isMobile ? "days" : "days mx-2"}>
                        <p>  Day 1 </p>
                      </div>
                      <div className="row">
                        <div  className="col-sm-2 text-center" style={{ padding: "0px" }} >
                          <img  src={Group135}   style={{ height: isMobile ? "50px" : "40px" }} />
                        </div>
                        <div className="col-sm-8" style={{ padding: "0px 20px 0px 20px" }} >
                          <h5> Safety Health and Environmental Awareness SHEA Core </h5>
                        </div>
                      </div>
                      <div className="price">
                        <p style={{ padding: "0px", }} >£125.00</p>
                      </div>
                      <div className="text-inner2">
                        <p className={!isMobile ? "P-m-height" : "P-L-height"}>The SHEA Core scheme is designed for individuals who require access to operational sites, both permanent and temporary, at all levels including operatives, supervisors and managers.
                      <br/>The SHEA Core scheme consists of 6 modules.
                      <br/>The 6 SHEA Core modules apply to all utility industries and reinforce workplace practices related to health, safety, and environmental regulations. The scheme serves as proof that an individual has acquired the necessary knowledge, understanding, and behavioral awareness.
                      <br/>To complete the scheme, individuals must undergo training and pass assessments for all six modules. However, it does not certify any level of technical skill or specialized expertise.

                        </p>
                      </div>
                    </div>
                    <div
                      className="row row-3"
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        width: "100%",
                        zIndex: "1",
                        height: "54px",
                      }}
                    >
                      <div
                        className="col-sm-6 BookNow2"
                        style={{ paddingRight: "0px" }}
                      >
                        <a href="https://cscs.msblue.uk/safety-health-and-environmental-awareness-shea-core/" className="btn" role="button">
                          Book Now&nbsp;<span>➜</span>
                        </a>
                      </div>
                      {/* {item?.schedule_url && ( */}
                        <div
                          className="col-sm-6 BookNow"
                          style={{ textAlign: "end", width: "50%" }}
                        >
                          {/* <a
                            // href={item?.schedule_url}
                            className="btn"
                            role="button"
                          >
                            Schedule &nbsp;<span>➜</span>
                          </a> */}
                        </div>
                      {/* // )} */}
                    </div>
                  </div>
                
              </div>
            </div>
          </section>
        </>
      ) : (
        <Outlet />
      )}
    </div>
  );
};
export default Training;
