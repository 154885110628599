import { useState } from "react";
import { routes } from "Constants/Routes";
import { images } from "assets/assets";
import { ButtonsWithIcon } from "components/Buttons/Buttons";
import "components/Header/header.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink,} from "react-router-dom";
import { useMobile } from "utils/CustomHooks/useDeviceResponsive";
const Navbars = () => {
  const [expanded, setExpanded] = useState(false);
  const closeNavbar = () => setExpanded(false);
  const Mobile=useMobile();
  return (
    <Navbar expand="lg" expanded={expanded} className={!Mobile && 'nav-padding' }>
      <div>
        <Navbar.Brand>
          <div className={"logo px-2"}>
          <NavLink to="/">
            <img src={images.logo} alt="Logo" />
          </NavLink>
          </div> 
        </Navbar.Brand>
      </div>
      <Navbar.Toggle className={Mobile && 'toggle-button'} aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
      <Navbar.Collapse id="basic-navbar-nav" className={!Mobile ? `flexEnd` : 'bg-white px-3'}>
        <Nav onClick={closeNavbar}>
          <NavLink className={"nav-link"} to={"/"}>
            Home
          </NavLink>
          <NavLink className={"nav-link"} to={"/consultancy"}>
            Consultancy
          </NavLink>
          <NavLink className={"nav-link"} to={"/training"}>
            Training
          </NavLink>
          <NavLink className={"nav-link"} to={"https://cscs.msblue.uk/"}>
          CSCS Card
          </NavLink>
          <NavLink className={"nav-link"} to={routes.contact_us}>
            <ButtonsWithIcon
              label={"Contact us"}
              buttonClass={"contact-button"}
              icons={<i class="bi bi-arrow-right"></i>}
            />
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navbars;
